"use client";

import { AdvertisementBlockType } from "@/types/page";
import AdBanner from "../GoogleAdSense/AdBanner";

export default function AdvertisementBlock({ block }: { block: AdvertisementBlockType }) {
  return (
    <div className="flex justify-center">
      <div
        className={
          "" +
          (block?.adType === "vertical"
            ? "w-full min-h-[550px] max-h-[750px] hidden lg:block "
            : block?.adType === "horizontal"
            ? "w-full h-full "
            : "w-full max-w-[350px] aspect-square")
        }
      >
        <div className="w-full h-full ">
          {process.env.NEXT_PUBLIC_ENABLE_ADS === "true" ? (
            block?.scriptContent ? (
              <AdBanner script={block?.scriptContent} />
            ) : (
              <AdBanner
                dataAdSlot={
                  block?.adType === "vertical"
                    ? "6237932871"
                    : block?.adType === "horizontal"
                    ? "6420662056"
                    : "5574484243"
                }
              />
            )
          ) : block?.adType === "horizontal" ? (
            <img
              src={block?.adType === "horizontal" ? "/images/temp/thumbnailHorizontal.png" : ""}
              alt="ad"
              className={`object-contain w-full ${block?.adType === "horizontal" ? "h-full" : ""}`}
            />
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}
