"use client";
import { useEffect } from "react";

type AdBannerProps = {
  // [key: string]: any;
  script?: string;
  dataAdSlot?: string;
};

const AdBanner = (props: AdBannerProps) => {
  // useEffect(() => {
  //   try {
  //     ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
  //   } catch (err) {
  //     console.log(err);
  //   }
  // }, []);

  useEffect(() => {
    const scriptElement = document.querySelector(
      `script[src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=${process.env.NEXT_PUBLIC_GOOGLE_AD_CLIENT_ID}"]`
    );

    const handleScriptLoad = () => {
      try {
        if (window.adsbygoogle) {
          console.log("adsense script is loaded, pushing ads");
          ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
        } else {
          scriptElement!.addEventListener("load", handleScriptLoad);
          console.log("waiting until adsense script is loaded");
        }
      } catch (err) {
        console.log(err);
      }
    };

    handleScriptLoad();

    return () => {
      if (scriptElement) {
        scriptElement.removeEventListener("load", handleScriptLoad);
      }
    };
  }, []);

  if (process.env.NEXT_PUBLIC_ENABLE_ADS !== "true") return null;

  return (
    <>
      {props.script ? (
        <div style={{ width: "100%" }} dangerouslySetInnerHTML={{ __html: props.script }} />
      ) : (
        <div style={{ width: "100%" }}>
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client={process.env.NEXT_PUBLIC_GOOGLE_ADS_CLIENT_ID}
            data-ad-slot={props.dataAdSlot}
            data-ad-format="auto"
            data-full-width-responsive="true"
          />
        </div>
      )}
    </>
  );
};

export default AdBanner;
