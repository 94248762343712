import {
  QUERY_INTERVIEWS,
  QUERY_INTERVIEWS_SLUG,
  QUERY_TOTAL_INTERVIEWS_COUNT,
} from "@/graphql/interviews";
import { InterviewType } from "@/types/interviews";
import envConfig from "@/config/envConfig";

export const fetchInterviews = async ({
  limit = 10,
  sort = "-createdAt",
  cache = "no-cache",
}: {
  limit?: number;
  sort?: "createdAt" | "-createdAt";
  cache?: RequestCache;
}) => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: QUERY_INTERVIEWS,
        variables: {
          limit,
          sort,
        },
      }),
      cache,
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.Interviews?.docs || []) as InterviewType[];
  } catch (err) {
    console.log(err);
    return [];
  }
};

export const fetchSingleDetailInterview = async ({
  slug,
  cache = "no-cache",
  token,
}: {
  slug: string;
  cache?: RequestCache;
  token?: string;
}) => {
  try {
    const response = await fetch(envConfig.publicServerUrl + `/api/v1/interviews/slug/${slug}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      cache,
    });

    // if (!response.ok) {
    //   throw new Error(`Error: ${response.statusText}`);
    // }

    const respData = await response.json();

    return (respData?.data || null) as InterviewType;
  } catch (err) {
    throw err;
  }
};

// fetch Interviews
export const fetchInterviewsList = async ({ limit, page }: { limit: number; page: number }) => {
  try {
    const response = await fetch(
      `${envConfig.publicServerUrl}/api/v1/interviews/all?limit=${limit}&page=${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
        cache: "no-cache",
      },
    );

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return respData;
  } catch (err) {
    console.error("Error:", err);
    return err;
  }
};

export const fetchTotalInterviewsCount = async () => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: QUERY_TOTAL_INTERVIEWS_COUNT,
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.Interviews?.totalDocs || 0) as number;
  } catch (err) {
    console.log(err);
  }
};

export const fetchAllInterviewsSlug = async ({
  page = 1,
  limit = 10,
}: {
  page?: number;
  limit?: number;
}) => {
  try {
    const response = await fetch(envConfig.publicQraphqlUrl, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        query: QUERY_INTERVIEWS_SLUG,
        variables: {
          page,
          limit,
        },
      }),
    });

    if (!response.ok) {
      throw new Error(`Error: ${response.statusText}`);
    }

    const respData = await response.json();
    return (respData?.data?.Interviews?.docs || []) as InterviewType[];
  } catch (err) {
    console.log(err);
  }
};
