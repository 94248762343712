export const QUERY_INTERVIEWS = `
  query Interviews($limit: Int = 5, $sort: String) {
    Interviews(where: { status: { equals: published } }, limit: $limit, sort: $sort) {
      docs {
        id
        image {
          alt
          url
          width
          height
        }
        title
        summary
        interviewee
        interviewer
        role
        updatedAt
        slug
      }
    }
  }
`;

export const QUERY_TOTAL_INTERVIEWS_COUNT = `
  query getInterviewsCount {
    Interviews(where: { status: { equals: published } }) {
      totalDocs
    }
  }
`;

export const QUERY_INTERVIEWS_SLUG = `
  query getInterviewsSlug($page: Int, $limit: Int) {
    Interviews(
      where: { status: { equals: published } }
      page: $page
      limit: $limit
    ) {
      docs {
        slug
        updatedAt
      }
    }
  }
`;
