"use client";

import { ReactNode, useRef, useState } from "react";
import { SlOptionsVertical } from "react-icons/sl";
import { FaLink } from "react-icons/fa";

import envConfig from "@/config/envConfig";
import { useHandleClickOutside } from "@/hooks";
import { socialShareOptions } from "@/data/social";
import { useMessage } from "../providers/MessageProvider";

interface SocialShareProps {
  title: string;
  url: string;
  customButton?: ReactNode;
}

export default function SocialShare({ title, url, customButton }: SocialShareProps) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const { showMessage } = useMessage();

  useHandleClickOutside(dropdownRef, () => setIsDropdownOpen(false));

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`${envConfig?.clientUrl}${url}`);
    showMessage("info", "Link copied to clipboard!");
  };

  return (
    <div className="flex items-center gap-3">
      {!customButton &&
        socialShareOptions?.slice(0, 3)?.map((item, index) => {
          const Icon = item?.icon;
          const shareLink = item.generateLink(`${envConfig.clientUrl}${url}`, title);

          return (
            <a
              key={index}
              href={shareLink}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={`Share on ${item.name}`}
              className="size-[30px] lg:size-[26px] rounded-md shrink-0 overflow-hidden bg-[#585858] text-white dark:text-black dark:bg-white  flex justify-center items-center"
            >
              <Icon />
            </a>
          );
        })}
      <div className="relative">
        {customButton ? (
          <div onClick={() => setIsDropdownOpen(true)}>{customButton}</div>
        ) : (
          <button
            className="size-[26px] flex justify-center text-[#585858] dark:text-white items-center "
            onClick={() => setIsDropdownOpen(true)}
            aria-label="More Options"
          >
            <SlOptionsVertical size={18} />
          </button>
        )}

        {isDropdownOpen && (
          <div
            ref={dropdownRef}
            className="absolute top-[100%] right-0 white shadow-md z-10 bg-white dark:bg-[#444] dark:text-white min-w-[180px] py-3 rounded"
          >
            <div
              className="flex items-center gap-2 cursor-pointer px-4 py-2 transition-colors hover:text-[#0055a5]"
              onClick={handleCopyLink}
            >
              <span className="flex items-center justify-center">
                <FaLink />
              </span>
              <span className="text-sm font-medium whitespace-nowrap">Copy Link</span>
            </div>
            {socialShareOptions?.map((item, index) => {
              const Icon = item?.icon;
              const shareLink = item.generateLink(`${envConfig.clientUrl}${url}`, title);

              return (
                <a
                  key={index}
                  href={shareLink}
                  target="_blank"
                  rel="noopener noreferrer"
                  aria-label={`Share on ${item.name}`}
                  className="flex items-center gap-2 px-4 py-2 transition-colors hover:text-[#0055a5]"
                >
                  <span className="flex items-center justify-center">
                    <Icon />
                  </span>

                  <span className="text-sm font-medium whitespace-nowrap">{item?.name}</span>
                </a>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
}
